<template>
	<div class="documentation--wrapper">
    <div class="backdrop" :class="{active: isSlide}"></div>
    <Header></Header>
    <!-- <header class="header">
      <div class="header--item">
        <nav class="nav" id="nav">
          <ul class="nav--list">
            <li class="nav--item logo">
              <router-link class="nav--link" to="/">
                <svg class="logo--icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M11 17a1 1 0 001.447.894l4-2A1 1 0 0017 15V9.236a1 1 0 00-1.447-.894l-4 2a1 1 0 00-.553.894V17zM15.211 6.276a1 1 0 000-1.788l-4.764-2.382a1 1 0 00-.894 0L4.789 4.488a1 1 0 000 1.788l4.764 2.382a1 1 0 00.894 0l4.764-2.382zM4.447 8.342A1 1 0 003 9.236V15a1 1 0 00.553.894l4 2A1 1 0 009 17v-5.764a1 1 0 00-.553-.894l-4-2z"></path>
                </svg>
              </router-link>
            </li>
            <li class="nav--item" v-if="profile">
              <router-link class="nav--link" to="/dashboard">Dashboard</router-link>
            </li>
            <li class="nav--item" v-if="!profile">
              <router-link class="nav--link" to="/login">Sign in</router-link>
            </li>
            <li class="nav--item" v-if="profile">
              <router-link class="nav--link" to=""><a @click="onLogout">Log out</a></router-link>
            </li>
            <li class="nav--item slide">
              <button v-if="isSlide" type="button" class="slider" @click="slide">&#10005;</button>
              <button v-else type="button" class="slider" @click="slide">&#9776;</button>
            </li>
          </ul>
        </nav>
      </div>
		</header> -->
		<div class="documentation">
			<aside class="aside--left" :class="{active: isSlide}">
				<div class="aside--container">
					<div class="aside--logo">
						<router-link class="nav--link logo" to="/">
							<svg class="logo--icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
								<path d="M11 17a1 1 0 001.447.894l4-2A1 1 0 0017 15V9.236a1 1 0 00-1.447-.894l-4 2a1 1 0 00-.553.894V17zM15.211 6.276a1 1 0 000-1.788l-4.764-2.382a1 1 0 00-.894 0L4.789 4.488a1 1 0 000 1.788l4.764 2.382a1 1 0 00.894 0l4.764-2.382zM4.447 8.342A1 1 0 003 9.236V15a1 1 0 00.553.894l4 2A1 1 0 009 17v-5.764a1 1 0 00-.553-.894l-4-2z"></path>
							</svg>
						</router-link>
						<button type="button" class="slider" @click="slide">&#10005;</button>
          </div>
					<div class="documentation--items">
						<ul class="documentation--lists">
							<li class="documentation--list" :class="{'active': sections.isAccount}" @click="section('isAccount');">
                <router-link to="/documentation/account"><h2>Account</h2></router-link>
							</li>
							<li class="documentation--list" :class="{'active': sections.isInsight}" @click="section('isInsight');">
                <router-link to="/documentation/insight"><h2>Insight</h2></router-link>
							</li>
							<li class="documentation--list" :class="{'active': sections.isDesigner}" @click="section('isDesigner');">
                <router-link to="/documentation/designer"><h2>Designer</h2></router-link>
							</li>
							<li class="documentation--list" :class="{'active': sections.isModel}" @click="section('isModel');">
                <router-link to="/documentation/model"><h2>Model</h2></router-link>
							</li>
							<li class="documentation--list" :class="{'active': sections.isPreview}" @click="section('isPreview');">
                <router-link to="/documentation/preview"><h2>Preview</h2></router-link>
							</li>
							<li class="documentation--list" :class="{'active': sections.isUser}" @click="section('isUser');">
                <router-link to="/documentation/user"><h2>User</h2></router-link>
							</li>
							<li class="documentation--list" :class="{active: sections.isSetting}" @click="section('isSetting');">
                <router-link to="/documentation/setting"><h2>Settings</h2></router-link>
							</li>
						</ul>
					</div>
				</div>
			</aside>
			<main class="main">
				<div class="main--container">
					<AccountDocumentation v-show="sections.isAccount"></AccountDocumentation>
          <DesignerDocumentation v-show="sections.isDesigner"></DesignerDocumentation>
          <InsightDocumentation v-show="sections.isInsight"></InsightDocumentation>
          <ModelDocumentation v-show="sections.isModel"></ModelDocumentation>
          <PreviewDocumentation v-show="sections.isPreview"></PreviewDocumentation>
          <SettingsDocumentation v-show="sections.isSetting"></SettingsDocumentation>
          <UserDocumentation v-show="sections.isUser"></UserDocumentation>
        </div>
				<Main></Main>
			</main>
		</div>
    <Footer></Footer>
	</div>
</template>

<script>
// @ is an alias to /src
import AccountDocumentation from "@/components/documentation/AccountDocumentation.vue";
import DesignerDocumentation from "@/components/documentation/DesignerDocumentation.vue";
import InsightDocumentation from "@/components/documentation/InsightDocumentation.vue";
import ModelDocumentation from "@/components/documentation/ModelDocumentation.vue";
import PreviewDocumentation from "@/components/documentation/PreviewDocumentation.vue";
import SettingsDocumentation from "@/components/documentation/SettingsDocumentation.vue";
import UserDocumentation from "@/components/documentation/UserDocumentation.vue";
import Header from "@/components/partials/Header.vue";
import Footer from "@/components/partials/Footer.vue";
import Main from "@/components/partials/Main.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Documentation",
  components: {
    AccountDocumentation,
    DesignerDocumentation,
    InsightDocumentation,
    ModelDocumentation,
    PreviewDocumentation,
    SettingsDocumentation,
    UserDocumentation,
    Header,
    Footer,
    Main,
  },
  data() {
    return {
      sections: {
        isAccount: true,
        isDesigner: false,
        isInsight: false,
        isModel: false,
        isPreview: false,
        isSetting: false,
        isUser: false,
      },
      isSlide: false,
      windowWidth: 0,
    };
  },
  watch: {
    windowWidth() {
      if (this.windowWidth >= 600) {
        this.isSlide = false;
      }
    },
  },
  computed: {
    ...mapGetters(["profile"]),
  },
  mounted() {
    const route = `is${this.$route.path
      .split("/")
      .pop()[0]
      .toUpperCase()}${this.$route.path.split("/").pop().slice(1)}`;
    this.section(route || "isAccount");
    window.addEventListener("resize", this.getWindowWidth);
    this.getWindowWidth();
  },
  methods: {
    ...mapActions(["logout"]),
    async onLogout() {
      await this.logout();
      this.$router.push({ name: "Login" });
      // window.location.reload();
    },
    onBlur(event) {
      event.target.style.borderColor = event.target.value
        ? "rgba(229,231,235, 1)"
        : "rgba(255, 0, 0, 1)";
    },
    slide() {
      this.isSlide = !this.isSlide;
    },
    async section(current) {
      if (this.isSlide) this.isSlide = !this.isSlide;

      if (this.sections[current]) return;
      else {
        for (let section in this.sections) {
          this.sections[section] = section === current ? true : false;
        }
      }
    },
    getWindowWidth() {
      this.windowWidth = window.innerWidth;
    },
  },
  async beforeDestroy() {
    this.isSlide = false;
    window.removeEventListener("resize", this.getWindowWidth);
  },
};
</script>

<style scoped>
/* documentaion */
.documentaion--wrapper {
  padding: 0;
  margin: 0;
  position: relative;
}
.backdrop {
  position: none;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}
.backdrop.active {
  position: fixed;
}
.slider {
  width: 2rem;
  height: 2rem;
  font-size: 2.5rem;
  font-weight: bolder;
  font-size: x-large;
  padding-right: 0.5rem;
  cursor: pointer;
  background-color: transparent;
}
.header {
  margin: 0;
  padding: 0;
  position: sticky;
  width: 100% !important;
  /*height: 80px;*/
  top: 0;
  background-color: rgba(243, 244, 246, 1);
}
#nav {
  padding: 10px;
}
.nav .nav--list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 0.75rem;
  /*padding-left: .75rem;*/
  /*padding-right: .75rem;*/
  width: 100%;
}
.nav .nav--item {
  list-style-type: none;
  margin-left: 1rem;
  /*margin-right: 1rem;*/
}
.nav--item .nav--link {
  color: #ffffff;
  color: #000;
  text-decoration: underline;
}
.nav--item.logo,
.nav--link.logo {
  flex: auto;
}
.logo--icon {
  width: 2rem;
  height: 2rem;
  fill: currentColor;
}
.documentation {
  display: flex;
  /*padding: 1rem 0;*/
  /*background-color: #f5f5f5; */
}
.aside--left,
.main {
  display: inline-block;
}
.aside--left {
  width: 20%;
  height: 100%;
  /* padding: 0.5rem 0; */
}
/* .aside--right {
  display: none;
} */
.aside--left {
  position: fixed;
  left: 0;
  top: 0;
  /*bottom: 0;*/
  z-index: 999;
  width: 0%;
  transition: 0.25s all ease-in-out;
  /*border-right: 1px solid rgba(209,213,219, 1);*/
}
.aside--left.active {
  width: 95%;
  background-color: rgba(243, 244, 246, 1);
  border-right: 1px solid rgba(209, 213, 219, 1);
  transition: 0.25s all ease-in-out;
}
.aside--container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  text-align: left;
  margin-top: 0.75rem;
}
.aside--logo {
  display: flex;
}
.documentation--items {
  margin-top: 1rem;
}
.documentation--lists {
  list-style-type: none;
}
.documentation--list {
  margin-top: 0.5rem;
  padding-left: 0.5rem;
  border: 2px solid rgba(229, 231, 235, 1);
}
.documentation--list a {
  color: #000;
}
.documentation--list.active {
  background-color: rgba(209, 213, 219, 1);
}
.documentation--list.active a {
  color: rgb(0, 124, 173);
}
.documentation--list:hover {
  background-color: rgba(209, 213, 219, 1);
}
.documentation--list:first-child {
  margin-top: 0rem;
}
.documentation--item {
  display: flex;
  justify-content: center;
  padding: 0rem;
  margin: 0rem;
  word-break: break-word;
  color: #050505;
  font-weight: 600;
  font-family: var(--font-family-segoe);
  text-align: left;
  line-height: 1.1765;
  word-wrap: break-word;
  font-size: 1.0625rem;
  box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px,
    rgb(60 64 67 / 15%) 0px 1px 3px 1px;
}
.documentation--item > a {
  width: 100%;
  padding: 0.5rem;
  text-align: center;
}
.documentation--links {
  display: flex;
  flex-direction: column;
}
.documentation--link {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
  border-bottom: 2px solid rgba(229, 231, 235, 1);
}
.documentation--link:last-child {
  border-bottom: none;
}
.main {
  width: 80%;
  height: 100%;
  padding: 0.5rem;
}
.main {
  background-color: rgba(243, 244, 246, 1);
  color: rgba(55, 65, 81, 1);
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-y: auto;
}
.main--container {
  width: 100%;
  height: 100%;
  margin-bottom: 5rem;
  overflow-y: auto;
}
.main--item {
  margin-top: 5rem;
  margin-bottom: 5rem;
}
.main--item:first-child {
  margin-top: 0rem !important;
}
/* mini screen */
@media screen and (min-width: 785px) {
  .slide {
    display: none;
  }
  .nav .nav--list {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0rem;
  }
  .logo--icon {
    width: 5rem;
    height: 5rem;
    fill: currentColor;
  }
  .aside--left,
  .main {
    display: inline-block;
  }
  .aside--left {
    height: 100%;
    width: 20% !important;
    position: fixed;
    top: unset;
  }
  .aside--container {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    margin-top: 0rem;
  }
  .aside--logo {
    display: none;
  }
  .main {
    width: 80%;
    height: 100%;
    margin-left: 20%;
    padding: 0rem 0.5rem;
    border-left: 1px solid rgba(209, 213, 219, 1);
  }
  .main--container {
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
  .documentation--items {
    margin-top: 0rem;
  }
}

/* max screen */
@media screen and (min-width: 981px) {
  .slide {
    display: none;
  }
  .nav .nav--list {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0rem;
  }
  .logo--icon {
    width: 5rem;
    height: 5rem;
    fill: currentColor;
  }
  .aside--left,
  .main {
    display: inline-block;
  }
  .aside--left {
    height: 100%;
    width: 20% !important;
    position: fixed;
  }
  .aside--container {
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
  .main {
    width: 100%;
    height: 100%;
    margin-left: 20%;
    padding: 0rem 1rem;
    border-left: 1px solid rgba(209, 213, 219, 1);
  }
  .main--container {
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
}
</style>